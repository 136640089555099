button{
    border: 0px;
    margin: 0px;
    padding: 18px 35px;
    border-radius:40px;
    line-height:30px;
    text-align:center;
    font-family:@font-secondary;
    text-transform:uppercase;
    font-size: 24px;
    letter-spacing: 2px;
    position:relative;
    transition: all .25s;
    background-color:@brand-primary;
    color:@primary-white;

    @media(max-width:@screen-tablet-portrait){
     font-size: 18px;
     padding: 5px 20px;
    }

    span{
        position:relative;
        z-index:2;
    }

    // &:before{
    //     content:" ";
    //     display:block;
    //     border-radius:1000px;
    //     background-color:black;
    //     width:100%;
    //     height:100%;
    //     transform: translate(-50%, -50%);
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transition:@trans-all;
    //     opacity:1;
    // }

    &:hover{
      background-color: @primary-dark;
    }

    &.btn-primary{
        background-color: @brand-primary;
        color:@primary-white;

        &:hover{
          background-color: @primary-dark;
          color:white;
        }
    }

    &.btn-secondary{
      background-color: @brand-primary;
      color:@primary-white;

      &:hover{
        background-color: @primary-dark;
          color:white;
      }
    }

    &.btn-tertiary{
      background-color: @brand-primary;
      color:@primary-white;

      &:hover{
        background-color: @primary-dark;
          color:white;
      }
    }
}

button.next.answer{
  margin: 0 auto;
  display: block;
}

//Custom radio buttons on questions page
.radio-buttons{
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  .radio-btn{
    color: #AAAAAA;
    display: block;
    position: relative;
    float: left;
    width: 25px;
    height: 60px;
  }

  .radio-btn input[type=radio]{
    position: absolute;
    visibility: hidden;
  }

  .radio-btn label{
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 1.35em;
    height: 60px;
    z-index: 9;
    cursor: pointer;
    transition: all 0.15s linear;
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
  }

  .radio-btn:hover label{
  	color: #FFFFFF;
  }

  .radio-btn .check{
    display: block;
    position: absolute;
    background-color: @radio-bg;
    border: 2px solid @radio-border;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    bottom: 0;
    left: 0;
  	z-index: 5;
  	transition: all .15s linear;
  }

  .radio-btn .check::before {
    display: block;
    position: absolute;
  	content: '';
    border-radius: 100%;
    height: 13px;
    width: 13px;
    top: 4px;
  	left: 4px;
    margin: auto;
  	transition: background 0.15s linear;
  }

  input[type=radio]:checked ~ .check::before{
    background: @radio-checked;
  }
}

@media(max-width:@screen-phablet){
  .radio-buttons{
    display: block;
    .radio-btn{
      width: 20%;
      display: block;
      margin-bottom: 20px;
    }

    .radio-btn .check{
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
