.cardslider {
    width: 100%;
    height: 100%;
    position: relative;
    //overflow: hidden
}

.cardslider__cards,
.cardslider__dots-nav {
    margin: 0;
    padding: 0;
    list-style: none
}

.cardslider__cards {
    max-width: 630px;
    width: 100%;
    height: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.cardslider__dots-nav {
    position: absolute;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%)
}

.cardslider__dot {
    display: inline-block;
    margin-right: 8px
}

.cardslider__dot-btn {
    position: relative;
    border-radius: 50%;
    border: 2px solid white;
    width: 10px;
    height: 10px;
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    padding: 0
}

.cardslider__dot-btn::before {
    content: ' ';
    position: absolute;
    display: block;
    background: white;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    -o-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0)
}

.cardslider__dot--active .cardslider__dot-btn::before {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    -o-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1)
}

.cardslider__card {
    background: #d7d7d7;
    display: block;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    position: absolute;
    will-change: transform, opacity, background;
    transition: all 0.5s ease-out
}

.cardslider__card--transitions {
    transition: all 0.5s ease-out,
}

.cardslider__card--index-0 {
    background: #fff
}

.cardslider__card--index-1 {
    background: #f5f5f5
}

.cardslider__card--index-2 {
    background: #ebebeb
}

.cardslider__card--index-3 {
    background: #e1e1e1
}

.cardslider--direction-up .cardslider__card {
    -webkit-transform: scale(.8) translate3d(0, 17.25%, 0);
    transform: scale(.8) translate3d(0, 17.25%, 0)
}

.cardslider--direction-up .cardslider__card--index-0 {
    -webkit-transform: translate3d(0, 0, 4px);
    transform: translate3d(0, 0, 4px)
}

.cardslider--direction-up .cardslider__card--index-1 {
    -webkit-transform: scale(.95) translate3d(0, 4.31%, 3px);
    transform: scale(.95) translate3d(0, 4.31%, 3px)
}

.cardslider--direction-up .cardslider__card--index-2 {
    -webkit-transform: scale(.9) translate3d(0, 8.61%, 2px);
    transform: scale(.9) translate3d(0, 8.61%, 2px)
}

.cardslider--direction-up .cardslider__card--index-3 {
    -webkit-transform: scale(.85) translate3d(0, 12.91%, 1px);
    transform: scale(.85) translate3d(0, 12.91%, 1px)
}

.cardslider--direction-up .cardslider__card--out {
    -webkit-transform: translate3d(0, 150%, 4px);
    transform: translate3d(0, 150%, 4px)
}

.question.cardslider__card{
    opacity: 0;
}


.question.cardslider__card.cardslider__card--index-0 {
    opacity: 1;
}
.question.cardslider__card.cardslider__card--index-1 {
    opacity: .8;
}

.question.cardslider__card.cardslider__card--index-2 {
    opacity: .6;
}


.cardslider--sortback-up {
    -webkit-animation: sortback-up 0.67s;
    -o-animation: sortback-up 0.67s;
    animation: sortback-up 0.67s
}

.cardslider--sortfront-up {
    -webkit-animation: sortfront-up 0.67s;
    -o-animation: sortfront-up 0.67s;
    animation: sortfront-up 0.67s
}

.cardslider--direction-down .cardslider__card {
    -webkit-transform: scale(.8) translate3d(0, -17.25%, 0);
    transform: scale(.8) translate3d(0, -17.25%, 0)
}

.cardslider--direction-down .cardslider__card--index-0 {
    -webkit-transform: translate3d(0, 0, 4px);
    transform: translate3d(0, 0, 4px)
}

.cardslider--direction-down .cardslider__card--index-1 {
    -webkit-transform: scale(.95) translate3d(0, -4.31%, 3px);
    transform: scale(.95) translate3d(0, -5.31%, 3px)
}

.cardslider--direction-down .cardslider__card--index-2 {
    -webkit-transform: scale(.9) translate3d(0, -8.61%, 2px);
    transform: scale(.9) translate3d(0, -10.61%, 2px)
}

.cardslider--direction-down .cardslider__card--index-3 {
    -webkit-transform: scale(.85) translate3d(0, -12.91%, 1px);
    transform: scale(.85) translate3d(0, -12.91%, 1px)
}

.cardslider--direction-down .cardslider__card--out {
    -webkit-transform: translate3d(0, 150%, 4px);
    transform: translate3d(0, 150%, 4px)
}

.cardslider--sortback-down {
    -webkit-animation: sortback-down 0.67s;
    -o-animation: sortback-down 0.67s;
    animation: sortback-down 0.67s
}

.cardslider--sortfront-down {
    -webkit-animation: sortfront-down 0.67s;
    -o-animation: sortfront-down 0.67s;
    animation: sortfront-down 0.67s
}

.cardslider--direction-right .cardslider__card {
    -webkit-transform: scale(.8) translate3d(-17.25%, 0, 0);
    transform: scale(.8) translate3d(-17.25%, 0, 0)
}

.cardslider--direction-right .cardslider__card--index-0 {
    -webkit-transform: translate3d(0, 0, 4px);
    transform: translate3d(0, 0, 4px)
}

.cardslider--direction-right .cardslider__card--index-1 {
    -webkit-transform: scale(.95) translate3d(-4.31%, 0, 3px);
    transform: scale(.95) translate3d(-4.31%, 0, 3px)
}

.cardslider--direction-right .cardslider__card--index-2 {
    -webkit-transform: scale(.9) translate3d(-8.61%, 0, 2px);
    transform: scale(.9) translate3d(-8.61%, 0, 2px)
}

.cardslider--direction-right .cardslider__card--index-3 {
    -webkit-transform: scale(.85) translate3d(-12.91%, 0, 1px);
    transform: scale(.85) translate3d(-12.91%, 0, 1px)
}

.cardslider--direction-right .cardslider__card--out {
    -webkit-transform: translate3d(150%, 0, 4px);
    transform: translate3d(150%, 0, 4px)
}

.cardslider--sortback-right {
    -webkit-animation: sortback-right 0.67s;
    -o-animation: sortback-right 0.67s;
    animation: sortback-right 0.67s
}

.cardslider--sortfront-right {
    -webkit-animation: sortfront-right 0.67s;
    -o-animation: sortfront-right 0.67s;
    animation: sortfront-right 0.67s
}

.cardslider--direction-left .cardslider__card {
    -webkit-transform: scale(.8) translate3d(17.25%, 0, 0);
    transform: scale(.8) translate3d(17.25%, 0, 0)
}

.cardslider--direction-left .cardslider__card--index-0 {
    -webkit-transform: translate3d(0, 0, 4px);
    transform: translate3d(0, 0, 4px)
}

.cardslider--direction-left .cardslider__card--index-1 {
    -webkit-transform: scale(.95) translate3d(4.31%, 0, 3px);
    transform: scale(.95) translate3d(4.31%, 0, 3px)
}

.cardslider--direction-left .cardslider__card--index-2 {
    -webkit-transform: scale(.9) translate3d(8.61%, 0, 2px);
    transform: scale(.9) translate3d(8.61%, 0, 2px)
}

.cardslider--direction-left .cardslider__card--index-3 {
    -webkit-transform: scale(.85) translate3d(12.91%, 0, 1px);
    transform: scale(.85) translate3d(12.91%, 0, 1px)
}

.cardslider--direction-left .cardslider__card--out {
    -webkit-transform: translate3d(150%, 0, 4px);
    transform: translate3d(150%, 0, 4px)
}

.cardslider--sortback-left {
    -webkit-animation: sortback-left 0.67s;
    -o-animation: sortback-left 0.67s;
    animation: sortback-left 0.67s
}

.cardslider--sortfront-left {
    -webkit-animation: sortfront-left 0.67s;
    -o-animation: sortfront-left 0.67s;
    animation: sortfront-left 0.67s
}

.cardslider__direction-nav {
    display: none;
    position: absolute;
    right: 20px;
    bottom: 20px
}

/*.cardslider__nav-next,
.cardslider__nav-prev {
    display: block;
    background: none;
    border: none;
    width: 40px;
    height: 40px;
    text-indent: -9999px;
    outline: none;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    border: 2px solid white;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 20px 20px;
    background-size: 20px 20px;
    padding: 0
}

.cardslider__nav-next:active,
.cardslider__nav-prev:active {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8)
}

.cardslider__nav-next {
    margin-bottom: 10px;
    background-image: url("../img/arrow-up.svg")
}

.cardslider__nav-prev {
    background-image: url("../img/arrow-down.svg")
}
*/

@-webkit-keyframes sortback-down {
    0% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        -webkit-transform: translate3d(0, 150%, 4px) scale(1);
        transform: translate3d(0, 150%, 4px) scale(1)
    }
    60% {
        -webkit-transform: translate3d(0, 150%, 0) scale(.8);
        transform: translate3d(0, 150%, 0) scale(.8)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@-o-keyframes sortback-down {
    0% {
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        transform: translate3d(0, 150%, 4px) scale(1)
    }
    60% {
        transform: translate3d(0, 150%, 0) scale(.8)
    }
    100% {
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@keyframes sortback-down {
    0% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        -webkit-transform: translate3d(0, 150%, 4px) scale(1);
        transform: translate3d(0, 150%, 4px) scale(1)
    }
    60% {
        -webkit-transform: translate3d(0, 150%, 0) scale(.8);
        transform: translate3d(0, 150%, 0) scale(.8)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@-webkit-keyframes sortfront-down {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        -webkit-transform: translate3d(0, 150%, 0) scale(.8);
        transform: translate3d(0, 150%, 0) scale(.8)
    }
    60% {
        -webkit-transform: translate3d(0, 150%, 4px) scale(1);
        transform: translate3d(0, 150%, 4px) scale(1)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@-o-keyframes sortfront-down {
    0% {
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        transform: translate3d(0, 150%, 0) scale(.8)
    }
    60% {
        transform: translate3d(0, 150%, 4px) scale(1)
    }
    100% {
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@keyframes sortfront-down {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        -webkit-transform: translate3d(0, 150%, 0) scale(.8);
        transform: translate3d(0, 150%, 0) scale(.8)
    }
    60% {
        -webkit-transform: translate3d(0, 150%, 4px) scale(1);
        transform: translate3d(0, 150%, 4px) scale(1)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@-webkit-keyframes sortback-up {
    0% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        -webkit-transform: translate3d(0, -150%, 4px) scale(1);
        transform: translate3d(0, -150%, 4px) scale(1)
    }
    60% {
        -webkit-transform: translate3d(0, -150%, 0) scale(.8);
        transform: translate3d(0, -150%, 0) scale(.8)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@-o-keyframes sortback-up {
    0% {
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        transform: translate3d(0, -150%, 4px) scale(1)
    }
    60% {
        transform: translate3d(0, -150%, 0) scale(.8)
    }
    100% {
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@keyframes sortback-up {
    0% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        -webkit-transform: translate3d(0, -150%, 4px) scale(1);
        transform: translate3d(0, -150%, 4px) scale(1)
    }
    60% {
        -webkit-transform: translate3d(0, -150%, 0) scale(.8);
        transform: translate3d(0, -150%, 0) scale(.8)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@-webkit-keyframes sortfront-up {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        -webkit-transform: translate3d(0, -150%, 0) scale(.8);
        transform: translate3d(0, -150%, 0) scale(.8)
    }
    60% {
        -webkit-transform: translate3d(0, -150%, 4px) scale(1);
        transform: translate3d(0, -150%, 4px) scale(1)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@-o-keyframes sortfront-up {
    0% {
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        transform: translate3d(0, -150%, 0) scale(.8)
    }
    60% {
        transform: translate3d(0, -150%, 4px) scale(1)
    }
    100% {
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@keyframes sortfront-up {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        -webkit-transform: translate3d(0, -150%, 0) scale(.8);
        transform: translate3d(0, -150%, 0) scale(.8)
    }
    60% {
        -webkit-transform: translate3d(0, -150%, 4px) scale(1);
        transform: translate3d(0, -150%, 4px) scale(1)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@-webkit-keyframes sortback-left {
    0% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        -webkit-transform: translate3d(-150%, 0, 4px) scale(1);
        transform: translate3d(-150%, 0, 4px) scale(1)
    }
    60% {
        -webkit-transform: translate3d(-150%, 0, 0) scale(.8);
        transform: translate3d(-150%, 0, 0) scale(.8)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@-o-keyframes sortback-left {
    0% {
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        transform: translate3d(-150%, 0, 4px) scale(1)
    }
    60% {
        transform: translate3d(-150%, 0, 0) scale(.8)
    }
    100% {
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@keyframes sortback-left {
    0% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        -webkit-transform: translate3d(-150%, 0, 4px) scale(1);
        transform: translate3d(-150%, 0, 4px) scale(1)
    }
    60% {
        -webkit-transform: translate3d(-150%, 0, 0) scale(.8);
        transform: translate3d(-150%, 0, 0) scale(.8)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@-webkit-keyframes sortfront-left {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        -webkit-transform: translate3d(-150%, 0, 0) scale(.8);
        transform: translate3d(-150%, 0, 0) scale(.8)
    }
    60% {
        -webkit-transform: translate3d(-150%, 0, 4px) scale(1);
        transform: translate3d(-150%, 0, 4px) scale(1)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@-o-keyframes sortfront-left {
    0% {
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        transform: translate3d(-150%, 0, 0) scale(.8)
    }
    60% {
        transform: translate3d(-150%, 0, 4px) scale(1)
    }
    100% {
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@keyframes sortfront-left {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        -webkit-transform: translate3d(-150%, 0, 0) scale(.8);
        transform: translate3d(-150%, 0, 0) scale(.8)
    }
    60% {
        -webkit-transform: translate3d(-150%, 0, 4px) scale(1);
        transform: translate3d(-150%, 0, 4px) scale(1)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@-webkit-keyframes sortback-right {
    0% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        -webkit-transform: translate3d(150%, 0, 4px) scale(1);
        transform: translate3d(150%, 0, 4px) scale(1)
    }
    60% {
        -webkit-transform: translate3d(150%, 0, 0) scale(.8);
        transform: translate3d(150%, 0, 0) scale(.8)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@-o-keyframes sortback-right {
    0% {
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        transform: translate3d(150%, 0, 4px) scale(1)
    }
    60% {
        transform: translate3d(150%, 0, 0) scale(.8)
    }
    100% {
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@keyframes sortback-right {
    0% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
    50% {
        -webkit-transform: translate3d(150%, 0, 4px) scale(1);
        transform: translate3d(150%, 0, 4px) scale(1)
    }
    60% {
        -webkit-transform: translate3d(150%, 0, 0) scale(.8);
        transform: translate3d(150%, 0, 0) scale(.8)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
}

@-webkit-keyframes sortfront-right {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        -webkit-transform: translate3d(150%, 0, 0) scale(.8);
        transform: translate3d(150%, 0, 0) scale(.8)
    }
    60% {
        -webkit-transform: translate3d(150%, 0, 4px) scale(1);
        transform: translate3d(150%, 0, 4px) scale(1)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@-o-keyframes sortfront-right {
    0% {
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        transform: translate3d(150%, 0, 0) scale(.8)
    }
    60% {
        transform: translate3d(150%, 0, 4px) scale(1)
    }
    100% {
        transform: translate3d(0, 0, 4px) scale(1)
    }
}

@keyframes sortfront-right {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(.8);
        transform: translate3d(0, 0, 0) scale(.8)
    }
    50% {
        -webkit-transform: translate3d(150%, 0, 0) scale(.8);
        transform: translate3d(150%, 0, 0) scale(.8)
    }
    60% {
        -webkit-transform: translate3d(150%, 0, 4px) scale(1);
        transform: translate3d(150%, 0, 4px) scale(1)
    }
    100% {
        -webkit-transform: translate3d(0, 0, 4px) scale(1);
        transform: translate3d(0, 0, 4px) scale(1)
    }
}
