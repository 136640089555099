body.templates-2-home{
    overflow-x: hidden;
}

@keyframes scrolldown {
  0%, 100% { margin-top:0px;margin-bottom:0px;}
  75% { margin-top:5px;margin-bottom:-5px;}
}

.page-home {

    .banner{
        background-color: @primary-dark;
        background-image: url(/img/templates/2/site/header-bg.jpg);
        background-position: center;
        background-size: cover;
        width:100%;
        height:100vh;
        text-align: center;

        .row{
            height:100vh;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-content: stretch;
            align-items: center;
        }

        .banner-text{

            p{
                margin-bottom:50px;
                color: #FFFFFF;
                font-size: 24px;

                @media(max-width:@screen-tablet-portrait){
                 font-size: 18px;
                }
            }

            h1{
               font-family: @font-secondary;
               color: #FFFFFF;
               line-height: 1.2;
               margin-bottom: 50px;

               @media(max-width:@screen-tablet-portrait){
                font-size: 24px;
               }
            }

            .btn-primary{
              background: none;
              border: 2px solid @brand-primary;

              &:hover{
                background-color: @primary-dark;
              }
            }
        }
    }

    .about{
        .columns{
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .left-column{
            width: 40%;
            background-image: url(/img/templates/2/site/about-bg.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;

            .about-logo{
              width: 201px;
              height: 201px;
              position: absolute;
              right: -100px;
              top: 50%;
              transform: translateY(-50%);
            }
        }

        .right-column{
            width: 60%;
            color: @primary-dark;
            padding: 155px 0;

            .col-inner{
              width: 100%;
              max-width: 420px;
              margin: 0 auto;
            }

            h5{
              font-size: 14px;
              font-family: @font-primary;
              text-transform: uppercase;
              color: @brand-primary;
            }

            h2{
              font-size: 36px;
              font-family: @font-secondary;
              margin: 30px 0 45px;
            }

            h4{
              font-size: 24px;
              font-family: @font-secondary;
              line-height: 1.2;
            }

            p{
                font-size: 14px;
                font-family: @font-primary;
                line-height: 1.3;
                margin-top: 20px;
            }

            .about-cta{
              width: 100%;
              display: flex;
              flex-direction: row;
              margin: 35px 0 50px;
              .cta-col{
                width: 50%;
                img{
                  width: 93px;
                  height: auto;
                }

                p{
                  font-family: @font-secondary;
                  font-size: 18px;
                  color: @brand-primary;
                  margin-top: 10px;
                }

                &:nth-of-type(2){
                  padding-left: 20px;
                }
              }
            }
        }
    }


    .how{
        background-color: #F9F9F9;
        padding: 50px 0;

        .categories{
            display: flex;
            justify-content:center;
            flex-wrap: wrap;

            .category{
                display: flex;
                padding: 15px;
                width: 50%;
                margin-top: 30px;

                .category-content{
                    padding:0 55px;
                }

                .image{
                    margin:0 auto;
                    width:129px;
                    height:129px;
                    margin-bottom:20px;
                    position:relative;

                    img{
                      width: 129px;
                      height: auto;
                    }
                }

                h4{
                    text-align:center;

                    span{
                        display:inline-block;

                        &:after{
                            content: " ";
                            display: block;
                            width: 40px;
                            height: 2px;
                            background-color: @brand-primary;
                            margin: 5px auto;
                        }
                    }
                }

                p{
                    line-height: 1.3;
                    text-align: center;
                    font-size: 14px;
                    font-family: @font-primary;
                }
            }
        }
    }

    .understanding{
      background-color: #E6EBEE;
      background-image: url(/img/templates/2/site/contours-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      padding: 40px 0 60px;

      .row{
        display: flex;
      }


      .left-column{
        display: flex;
        flex-direction: column;
        justify-content: center;

        h5{
          font-family: @font-primary;
          color: @brand-primary;
          text-transform: uppercase;
        }
        h1{
          font-size: 88px;
        }
      }

      .right-column{
        img{
          width: 100%;
          max-width: 523px
        }

        text-align: center;
      }
    }

    .discover-score{
        padding: 60px 0;
        background-color:#FFFFFF;

        h2{
          font-size: 36px;
          margin-bottom: 40px;
        }

        h3{
          font-size: 24px;
          padding: 0 180px;
        }

        h2, h3, p{
            color: @primary-dark;
        }

        p{
            margin:30px 0;
        }

        img{
            padding-bottom:50px;
        }

        .get-book{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: 30px;

          img{
            padding: 0;
            width: 175px;
          }

          div{
            text-align: left;

            p{
              font-size: 24px;
              margin: 0;
              span{
                color: @brand-primary;
                position: relative;
                &:after{
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: -8px;
                  width: 100%;
                  height: 2px;
                  background-color: @brand-primary;
                }
              }

              &:nth-of-type(2){
                font-size: 10px;
                font-family: @font-primary;
                color: #7E7E7E;
              }
            }
          }
        }

        .row{
            margin-bottom:10px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-content: stretch;

            .discover-item{

                h3{
                  font-size: 36px;
                  padding: 0;
                  position: relative;

                  &:after{
                    content: '';
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 40px;
                    height: 2px;
                    background-color: @brand-primary;
                  }
                }

                p{
                  margin: 18px 0;
                }
            }
        }
    }

    @media(max-width:@screen-tablet-portrait){

      .about{
        .columns{
          flex-direction: column;

          .left-column{
            width: 100%;
            height: auto;
            background: none;

            .about-logo{
              position: inherit;
              margin: 0 auto;
              top: 30px;
              right: inherit;
              left: inherit;
              width: 88px;
              height: auto;
              transform: none;

              img{
                width: 100%;;
                height: auto;
              }
            }
          }

          .right-column{
            width: 100%;
            padding: 50px 20px;
            text-align: center;

            h4{
              font-size: 18px;
            }

            .about-cta{
              .cta-col{
                img{
                  width: 67px;
                  height: auto;
                }
              }
            }
          }
        }
      }

      .how{
        padding-top: 50px;

        h2{
          font-size: 18px;
          line-height: 1.3;
        }

          .categories{

              .category{
                  width:50%;

                  .image{
                    width: 88px;
                    height: auto;
                    img{
                      width: 88px;
                    }
                  }
              }
          }
      }

      .understanding{
        text-align: center;
        padding: 40px 30px 60px;
        .row{
          flex-direction: column;
          .left-column{
            width: 100%;
            h1{
              font-size: 36px;
            }
          }

          .right-column{
            width: 100%;
          }
        }
      }

      .discover-score{
        padding: 30px 0;

        h2{
          font-size: 24px;
        }

        h3{
          padding: 0;
          font-size: 18px;
        }

        .get-book{
          img{
            width: 97px;
          }

          div{
            tex-align: center;
            p{
              font-size: 18px;
            }
          }
        }
      }


      .steps-section{
        .columns{
          flex-direction: column;

          .left-column{
            width: 100%;
            padding-top: 50px;

            .col-inner{
              max-width: none;
              padding: 0 20px;
            }
          }

          .right-column{
            width: 100%;
            padding: 0 20px;
          }
        }
      }

      .testimonials{
        padding: 50px 0;

        h2{
          font-size: 30px;
        }
      }
    }

    // @media(max-width:@screen-tablet-landscape){
    //
    //     .how{
    //       padding-top: 50px;
    //
    //         .categories{
    //
    //             .category{
    //                 width:50%;
    //             }
    //         }
    //     }
    // }

    @media(max-width:@screen-tablet-landscape){

        .banner{
            padding:0px 0px !important;
        }

        .about{

            .row{
                display:block;

                .play-button{
                    height: 90px;
                    margin-top: 30px;
                }
            }
        }

        .steps-section{

            h5{
                margin-bottom:10px;
            }

            .step{
                display:block;

                .text{
                    padding: 0 15px !important;
                    width:100%;
                }

                .image{
                    display:none;
                }
            }

            .report{
                margin-top: 50px;
            }

            .report-info{

                .info{

                    p{
                        border-bottom: 1px solid #ccc;
                        margin-bottom: 40px;
                        padding-bottom: 30px;
                    }

                    h4{
                        margin-top: 20px;
                        margin-bottom: 0px;
                    }

                    img{
                        margin:0px 0px;
                    }
                }
            }
        }

        .testimonials .slide.slick-center .person img{
            width:80px;
            height:80px;
        }

        .testimonials .slide .person img {
            width: 60px;
            height: 60px;
        }

        .discover-score{

            .row{
                display:block;
                margin-bottom: 20px;
                padding-top: 10px;
            }

            p{
                margin:15px 0;
            }

            img{
                padding-bottom: 25px;
            }
        }
    }

    @media(max-width:@screen-phablet){


        .how{

            .categories{

                .category{
                    width:100%;
                }
            }
        }
    }
}
