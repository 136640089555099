.templates-2-questions{
  .site-footer{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;

  }
}

.site-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  background-color: @secondary-dark;
  padding: 12px 30px;
  .col{
    width: 33.333%;

    p{
      color: #FFFFFF;
      font-family: @font-primary;
      line-height: 1.3;
      margin: 0;
    }

    &:nth-of-type(1){
      img{
        width: 65px;
        height: 65px;;
      }
    }

    &:nth-of-type(2){
      text-align: center;
      p{
        font-size: 10px;
        span{
          color: @brand-primary;
        }
      }
    }

    &:nth-of-type(3){
      text-align: right;
      p{
        font-size: 14px;

        i{
          font-size: 18px;
          color: @brand-primary;
          margin: 0 5px;
        }
      }
    }
  }

  @media(max-width:@screen-tablet-portrait){
    flex-direction: column;
    position: unset!important;
    margin-top: 100px;
    display: none;
    .col{
      width: 100%;
      order: 2;

      &:nth-of-type(3){
        text-align: center;
        order: 1;
        margin-bottom: 20px;
      }
    }
  }
}
