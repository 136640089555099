body.templates-2-questions{
    background-color: white;
    height: 100%;

    .site-header{
      top: 10px;
    }
}

.templates-2-questions {

    /*
        -- Section Questions
    */



    .questions-container{
        height: 550px;
        margin-bottom: 50px;


        &.type-2{
          height: 580px;

          .question-content{
            margin-bottom: 0;

            p{
              &:nth-of-type(1){
                font-size: 24px;
                padding: 0 10%;
              }

              &:nth-of-type(2){
                font-family: @font-primary;
                font-size: 14px;
                line-height: 1.3;
              }
            }
          }
        }
    }

    .section-questions {
        opacity: 0;
        padding: 0 20px;
        transition: opacity .4s;

        .loader {
            display: none;
            width: 65px;
            margin: 0 auto;
            margin-top: 15%;

            img {
                max-width: 100%;
            }
        }


        .question {
            margin: 20px auto 0;
            outline: 0;
            min-height: 100px;
            background-color: #333333;
            max-width: 630px;
            padding: 57px 55px 50px 55px;
            border-radius: 0;

            p{
              color: #FFFFFF;
            }


            .back-arrow{
                position: absolute;
                top: 20px;
                left: 40px;

                a{
                    color: #FFFFFF;
                }

                span, i{
                    float:left;
                }

                span{
                    font-size: 18px;
                    margin-left: 10px;
                    line-height:26px;
                    height:26px;
                }

                i{
                    font-size:26px;
                    line-height:26px;
                    height:26px;
                    transition:@trans-all;
                }

                &:hover{

                    i{
                        margin-left:-5px;
                        margin-right:5px;
                    }
                }
            }

            .prog{

                .number-done{
                    text-transform: uppercase;
                    color: @brand-primary;
                }
            }

            &.active {
                //display: block;
            }

            .question-content{
                margin-bottom: 30px;
            }


            .question-footer{
                position: absolute;
                bottom:0;
                left: 0;
                width: 100%;
                height: 50px;
                line-height: 48px;
                text-align: center;
                font-size: 18px;
                font-family: @font-secondary;
                color: @brand-primary;
                border-top: solid #E3E9F1 2px;
                background-color:#FBFBFB;
            }

            .buttons{

                button{
                    margin:0 10px;
                }
            }

            span.error-msg{
              color: red;
              display: block;
              text-align: center;
              margin-top: 5px;
            }
        }

        .question-content{
            min-height: 90px;
        }

        .radio-scale{
          color: #FFFFFF;
          text-transform: uppercase;
          position: relative;
          height: 35px;
          font-family: @font-secondary;
          font-size: 18px;

          .poor{
            position: absolute;
            top: 0;
            left: 0;
          }

          .excellent{
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .buttons {
            text-align: center;
        }

        &.show-questions{
          opacity: 1;
        }
    }

    /*
        --Cardslider
    */

    .cardslider__cards{
      top: 410px;
    }

    .cardslider--direction-down .cardslider__card--out {
      transform: translate3d(0, 100%, 4px);
      opacity: 0!important;
    }

    .cardslider--direction-down .cardslider__card{
        background-color:#D8D8D8;
        transition:@trans-all;
        border-radius:5px;
    }

    .cardslider--direction-down .cardslider__card--index-0{
        opacity:1;
        background-color: #333333;
        box-shadow: 0 4px 6px 0 rgba(0,0,0,0.10);

    }

    .cardslider--direction-down .cardslider__card--index-1{
        opacity:1;
        background-color: #4A4A4A;
    }

    .cardslider--direction-down .cardslider__card--index-2{
        opacity:1;
        background-color: #5B646F;
    }


    /*
        -- Bottom Bar
    */

    .section-banner {
        background-color: @brand-primary;
        height: 125px;

        .container {
            height: 125px;

            .row {
                display: flex;
                align-items: center;
                height: 125px;

                p {
                    margin-left: 215px;
                }

                img {
                    position: relative;
                    top: -60px;
                    margin-left: 40px;
                    margin-right: 215px;
                }
            }
        }
    }

    .site-footer {
        .footer-nav {
            display: none;
        }
    }

    @media(max-width:@screen-phablet){

        .questions-container {
            height: 450px;
            margin-bottom: 0px;
            margin-top: 0px;
        }

        .cardslider--direction-down .cardslider__card{
            padding: 15px 15px;
        }

        .cardslider__cards{
          top: 390px;
          height: 550px;
        }



        .section-questions .question{
          margin-bottom: 30px;

            .buttons button {
                margin: 0 5px;
            }

            .question-footer{
                padding:0 15px;
                text-align: center;
            }

            .back-arrow{
                position: absolute;
                bottom: 12px;
                top: auto;
                left: 15px;
                z-index: 3;
            }
        }
    }
}
