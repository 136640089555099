
.modal {
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  transform: translate3d(0, 0, 0);
  color:@primary-dark;

  h1, h2, h3, h4, h5, h6, p{
    color:white;
  }

  .modal-wrapper {
    max-width: 630px;
    display: block;
    margin: 0 auto;
    position: relative;
    margin-bottom: 50px;
    margin-top: 100px;
    background-color: #FFFFFF;

    .close-btn{
      position: absolute;
      right: -25px;
      top: -25px;
      font-size: 20px;
      transition: all .25s;
      color: #FFFFFF;
      opacity: .6;

      &:hover{
        cursor: pointer;
        transform: scale(1.2);
        opacity: 1;
      }
    }
  }

  .modal-panel {
    width: 100%;
    background-color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 15px;
  }


  .modal__header {
    margin-bottom: 75px;
    position: relative;

    img{
      width: 130px;
      height: 130px;
      position: absolute;
      top: -75px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .step-count{
      text-align: right;
      p{
        color: @primary-dark
      }
    }

    .form-errors{
      position: absolute;
      top: 60px;
      font-size: 12px;
      color: red;
      width: 100%;
      text-align: center;

      .field-msg,.email-msg{
        opacity: 0;
        transition: all .25s;
        display: block;

        &.show-msg{
          opacity: 1;
        }
      }
    }

    .flex {
      display: flex;
      margin: 0 auto;
      align-items: center;
      flex-direction: column;


      h4 {
        text-transform: uppercase;
        line-height: 26px;
        position: relative;
        margin: 0;
        top: -10px;
        color: @primary-dark;
      }
    }
  }

  .modal__content {
    padding: 0 0 7px 0;

    .text-inputs {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      input,select,textarea{
        transition: all .25s;
        &.error-required{
          border-color: #c55959;
          background-color: #f1e6e6;
        }

        &.validated{
          border-color: #a0e4a0;
        }
      }

      input {
        width: 100%;
        height: 42px;
        margin-bottom: 12px;
        padding: 10px 20px;
        outline: 0 !important;
        border: 1px solid #c3d4de;
        color: @primary-dark;
        background-color: #F7F7F7;

        &::placeholder{
          color: #A2A0A1;
        }
      }
    }

    select{
      width: 47%;
      height: 42px;
      border: 1px solid #c3d4de;
      background: none;
      border-radius: 0;
      -webkit-appearance: none;
      padding: 10px 20px;
      background-color: #F7F7F7;
      background-image: url(/img/templates/2/site/select-arrow.png);
      background-repeat: no-repeat;
      background-size: 9px;
      background-position: 92% 50%;
      color: #A2A0A1;
    }

    .separator {
      background-color: #D0DBE2;
      width: 100%;
      height: 1px;
      margin-bottom: 20px;
    }

    .step-1{
      .select-inputs{
        display: flex;
        justify-content: space-between;
      }
    }

    .step-2{
      display: none;
      padding: 0 15px 25px;

      select{
        width: 100%;
        margin-bottom: 12px;
      }

      textarea{
        width: 100%;
        height: 145px;
        margin-bottom: 12px;
        padding: 10px 20px;
        outline: 0 !important;
        border: 1px solid #c3d4de;
        color: @primary-dark;
        background-color: #F7F7F7;

        &::placeholder{
          color: #A2A0A1;
        }
      }
    }

    button.btn-secondary{
      padding: 18px 65px;
      background-color: @primary-dark;

      &:hover{
        color: #FFFFFF;
      }
    }
  }

  .get-book{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 20px 0 20px 0;

      img{
        padding: 0;
        width: 131px;
      }

      div{
        text-align: left;

        p{
          font-size: 24px;
          margin: 0;
          color: @primary-dark;
          text-align: center;
          span{
            color: @brand-primary;
            position: relative;
            &:after{
              content: '';
              position: absolute;
              left: 0;
              bottom: -8px;
              width: 100%;
              height: 2px;
              background-color: @brand-primary;
            }
          }

          &:nth-of-type(2){
            font-size: 10px;
            font-family: @font-primary;
            color: #7E7E7E;
          }
        }
      }

      &:hover{
        text-decoration: none;
      }
  }

  .modal__footer {
    p {
      text-align: center;
      margin-top: 15px;
    }
  }

  .validation-errors {
    .modal__content {
      span.invalid-postcode {
        display: block;
      }
    }
  }

  @media screen and (max-height: 820px) {
    height: auto;

    .overflow {
      height: auto;
    }

    .modal-wrapper {
      top: 0px;
      transform: none;
    }
  }

  @media screen and (max-width: @screen-phablet) and (max-height: 990px) {
    .modal-wrapper {
      top: 0px;
      transform: none;
    }
  }


  @media screen and (max-width: @screen-phablet) {

    img.logo {
      display: none;
    }

    .modal__header {
      .flex {
        flex-direction: column;

        img {
          margin-right: 0px;
          margin-bottom: 10px;
        }

        h4 {
          text-align: center;
          max-width: 300px;
        }
      }
    }

    .modal__content {
      .text-inputs {
        input {
          flex-basis: 100%;
        }
      }
      .gender {
        margin-top: 15px;
        flex-wrap: wrap;
        span {
          margin: 0px;
          flex-basis: 100%;
          margin-bottom: 10px;
        }
        .radio {
          margin-right: 15px;
        }
      }
    }
  }
}

.validate{
  height: 42px;
  margin-top:25px;

  .spinner{
    display: none;
    text-align: center;
  }
}


body.modal-open {
  .modal {
    background-color: rgba(0, 40, 71, 1);
  }
}

@media screen and (max-width: @screen-phablet) {
  .modal-panel {
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal .modal__content{
    padding:0px 0px;
  }

  .modal{

    button{
      padding: 10px 20px;
    }
  }
}
