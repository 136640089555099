.site-header {

    position:absolute;
    top: 95px;
    left: 0px;
    width: 100%;

    .logo{
      width: 100%;
      text-align: center;
      img{
          width: 130px;
          height: auto;
      }
    }

    @media(max-width:@screen-tablet-portrait){
      top: 50px;

      .logo{
        width: 100%;
        text-align: center;
        img{
            width: 88px;
            height: auto;
        }
      }
    }
}
