body.templates-2-results {
    font-family:@font-primary;

    .logo{
        padding: 40px 80px;
        text-align: center;

        img{
            width: 130px;
            height: auto;

            @media(max-width:@screen-tablet-portrait){
              width: 88px;
              height: auto;
            }
        }
    }

    .overall-score{
        width:300px;
        height:300px;
        margin: 0 auto;
        position:relative;
        border: 12px solid @results-primary;
        border-radius: 100%;
        font-family: @font-secondary;

        .text, .percentage{
            text-align:center;
        }

        .text{
            font-size:18px;
            color: @brand-primary;
            line-height:22px;
            text-transform: uppercase;
        }

        .percentage{
            color: @primary-dark;
            font-size: 88px;
            line-height: 106px;
        }
    }

    h2{
      font-size: 28px;
      color: @primary-dark;
      line-height: 1.2;
    }

    .about{
      h2{
        margin-top: 0;

        @media(max-width:@screen-tablet-portrait){
          margin-top: 30px;
        }
      }
      .subheader{
        font-family: @font-secondary;
        color: @brand-primary;
        font-size: 18px;
        margin-bottom: 30px;

        .change-email{
            cursor:pointer;
            transition:@trans-all;
            color: #A7A5A6;
            text-decoration: underline;

            &:hover{
                color: @primary-dark;
            }
        }
      }

      p{
        font-family: @font-primary;
        font-size: 14px;
        line-height: 1.3;
      }
    }

    .categories{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 78px;
        padding-bottom: 25px;

        @media(max-width:@screen-tablet-portrait){
          margin-top: 20px;
        }

        .category{
            display: flex;
            padding: 15px;
            width: 25%;

            @media(max-width:@screen-tablet-portrait){
              width: 100%;
            }

            .category-content{
                padding:20px 20px 5px 20px;
                border:4px solid #EEEEEE;
                text-align: center;

                @media(max-width:@screen-tablet-portrait){
                  width: 100%;
                }

                .image{
                    width: 100%;
                    text-align: center;
                    margin-bottom:30px;
                    position: relative;

                    img{
                      width: 93px;
                      height: auto;
                    }
                }

                .title{
                    font-size:18px;
                    font-family: @font-secondary;
                    position: relative;

                    &:after{
                      content: '';
                      height: 2px;
                      background-color: @brand-primary;
                      width: 40px;
                      position: absolute;
                      bottom: -10px;
                      left: 0;
                      right: 0;
                      margin: 0 auto;
                    }
                }

                p{
                    margin:25px 0;
                    font-family: @font-primary;
                    font-size: 14px;
                    line-height: 1.3;
                    height: 40px;
                }

                .score{
                    font-family: @font-secondary;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    .text{
                        font-size: 18px;
                        color: @primary-dark;
                        padding-right: 5px;
                    }

                    .value{
                        font-size: 24px;
                        color: @brand-primary;
                        padding-left: 5px;
                    }
                }

                .score-percentage{
                  font-size: 42px;
                  color: @brand-primary;
                  font-family: @font-secondary;
                }
            }
        }
    }

    .understanding{
      background-color: #E6EBEE;
      background-image: url(/img/templates/2/site/contours-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      padding: 50px 0 80px;

      @media(max-width:@screen-tablet-portrait){
        text-align: center;
      }

      .row{
        display: flex;

        @media(max-width:@screen-tablet-portrait){
          display: block;
        }
      }


      .left-column{
        display: flex;
        flex-direction: column;
        justify-content: center;

        h5{
          font-family: @font-primary;
          color: @brand-primary;
          text-transform: uppercase;
          font-size: 14px;
        }
        h1{
          font-size: 42px;
        }
      }

      .right-column{
        img{
          width: 100%;
          max-width: 307px
        }

        text-align: center;
      }
    }

    .section-social-shares{
        background-color: @brand-primary;
        padding: 35px 0;
        color:white;

        h2{
            margin-top: 0px;
            color: white;
            margin-bottom: 20px;
            font-size: 42px;
        }

        .shares{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
        }

        .total-shares{
            border-right:3px solid white;
            padding-right: 30px;
            margin-right: 30px;

            .js-total{
                display: block;
                font-size: 88px;
                line-height: 77px;
                font-family: @font-secondary;
                text-align: right;

                @media(max-width:@screen-tablet-portrait){
                  font-size: 42px;
                }
            }

            .label{
                font-weight:normal;
                font-size:24px;
                line-height:29px;
                padding:0px;
                color:white;
                font-family: @font-secondary;
            }
        }

        .share-option{

            .link{
                display: block;
                width: 70px;
                height: 70px;
                border: 1px solid white;
                border-radius: 70px;
                text-align: center;
                line-height: 70px;
                font-size: 30px;
                margin: 0 auto;
                margin-bottom: 10px;
                color: white;
                transition:@trans-all;

                &:hover{
                    opacity:0.7;
                }

                @media(max-width:@screen-tablet-portrait){
                  width: 36px;
                  height: 36px;
                  line-height: 36px;
                  font-size: 18px;
                }
            }

            .share-number{
                text-align:center;
                color:white;
                font-weight:700;
                font-size:18px;
                font-family:@font-secondary;
                text-align:center;
                display:block;
            }
        }
    }

    #changeemail-popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: all 0.4s;

        &.show {
            opacity: 1;
            visibility: visible;
        }

        .overlay {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            background: rgba(14, 36, 45, 0.7);
            height: 100%;
            width: 100%;
        }

        form {
            max-width: 300px;
            margin: 0 auto;
            background-color: @results-primary;
            padding: 25px;

            .circle {
                position: absolute;
                top: -12px;
                right: -9px;
                cursor: pointer;
                background-color: #0e242d;
                border-radius: 50%;
                width: 23px;
                height: 23px;
                text-align: center;
                background-color: #0e242d;

                i {
                    color: #fff;
                    line-height: 22px;
                }
            }

            input[type="text"] {
                width: 100%;
                margin-bottom: 20px;
                background-color: #FBFBFB;
                font-size:14px;
                padding: 15px 20px;
                outline: 0 !important;
                border: 1px solid #F2f2f2;
            }

            input[type="submit"] {
                width: 100%;
            }
        }
    }

    @media(max-width:@screen-laptop){

        .categories{

            .category{
                padding: 20px;
            }
        }
    }

    @media(max-width:@screen-tablet-landscape){

        .overall-score{
            width:240px;
            height:240px;
        }

        .categories{

            .category{
                width:50%;
            }
        }
    }

    @media(max-width:@screen-tablet-portrait){

        .logo{
            padding: 15px;
        }

        .about{

            .subheader{
                font-size:18px;
                line-height:22px;
            }
        }

        .overall-score{
            margin:0 auto;
        }

        .categories{

            .category{
                width:100%;
                padding:10px 0;
            }
        }

        .total-shares{
            text-align: center;
            margin-bottom: 30px;
            border:0px !important;
        }

        .shares{
            padding: 0 15px;

            .share-option{
                margin-bottom:15px;
            }
        }
    }
}
